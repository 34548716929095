/** @jsx jsx */
import "./../normalizer.css"
import { jsx } from "theme-ui"
import Header from "./header"
import { ReactNode } from "react"
import Footer, { FooterProps } from "./footer"
import { FixedCTAProps } from "../../fixed-cta"
import { useStaticQuery, graphql } from "gatsby"
import { DefaultNavigationProps } from "./header/navigation/default"

/**
 * Type definition for props required for "MainLayout" component.
 *
 * @interface
 */
interface LayoutProps
  extends DefaultNavigationProps,
    FooterProps,
    FixedCTAProps {
  children: ReactNode
}

/**
 * Component rendering child components with main "Header" & "Footer".
 *
 * @param {LayoutProps} param
 *
 * @returns {JSX.Element}
 */
const MainLayout = ({
  routeId,
  children,
  pageTitle,
  hasCTA,
}: LayoutProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div sx={{ fontFamily: "body", bg: "white", m: 0 }}>
      <Header routeId={routeId} title={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer routeId={routeId} pageTitle={pageTitle} hasCTA={hasCTA} />
    </div>
  )
}

export default MainLayout
