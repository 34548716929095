import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../../../helpers/theme"

/**
 * Default Navigation - Container (nav)
 */
export const defaultNavigationContainerStyles: SxStyleProp = {
  display: createResponsiveStyles("none", "none", "flex"),
  "> ul": {
    m: 0,
    justifyContent: "space-between",
    alignItems: "center",
    listStyleType: "none",
    "> li": {
      mt: "10px",
      mb: "10px",
      mr: [5, 5, 6, 6, 8],
    },
  },
}

/**
 * Collapsible Navigation - Main (Flex)
 */
export const collabsibleNavigationContainerStyles: SxStyleProp = {
  display: createResponsiveStyles("flex", "flex", "none"),
  alignItems: "center",
}

/**
 * Collapsible Navigation - Hamburger Icon (button)
 */
export const collapsibleNavigationHamburgerStyles: SxStyleProp = {
  padding: "0",
  border: "none",
  textAlign: "center",
  path: { fill: "secondary" },
  backgroundColor: "transparent",
  width: createResponsiveStyles("26px", "40px", "40px"),
}

/**
 * Collapsible Navigation - Hamburger Icon (HarmburgerIcon)
 */
export const collapsibleNavigationHamburgerIconStyles: SxStyleProp = {
  width: "100%",
  display: "inline-block",
  verticalAlign: "middle",
}

/**
 * Collapsible Navigation - Sidebar (Box)
 */
export const collapsibleNavigationSidebarStyles: SxStyleProp = {
  top: 0,
  right: 0,
  width: 0,
  zIndex: "collapsibleNavigation",
  bg: "white",
  height: "100%",
  display: "block",
  position: "fixed",
  overflowX: "hidden",
  transition: "all 0.5s ease",
  "&.active": {
    width: "100%",
  },
}

/**
 * Collapsible Navigation - Sidebar Container (Box)
 */
export const collabsibleNavigationSidebarContainerStyles: SxStyleProp = {
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "> ul": {
    p: 0,
    m: 0,
    listStyleType: "none",
    flexDirection: "column",
    justifyContent: "center",
    "> li": {
      mt: 5,
      textAlign: "center",
    },
  },
}

/**
 * Collapsible Navigation - Close Button (Button)
 */
export const collabsibleNavigationCloseButtonStyles: SxStyleProp = {
  variant: "buttons.default",
  color: "black",
  position: "absolute",
  top: 0,
  right: 0,
  fontSize: [1],
  m: [3, 4, 5],
}

/**
 * Collapsible Navigation - List Styles (li)
 */
export const collapsibleNavigationListStyles: SxStyleProp = {
  minWidth: "175px",
}
