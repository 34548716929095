/** @jsx jsx */
import { Logo } from "./images"
import { Fixed } from "../../../common"
import { Link } from "gatsby-plugin-intl"
import { jsx, Flex, Box } from "theme-ui"
import { useState, useEffect } from "react"
import Navigation, { NavigationProps } from "./navigation"
import { headerContainerStyles, headerInnerStyles } from "./styles"
import withWidth, { isWidthUp, WithWidth } from "@material-ui/core/withWidth"

import CloseIcon from "@material-ui/icons/Close"
import { useIntl } from "react-intl"
import { translate } from "../../../../helpers/grammar"

/**
 * Component related to the header of the main layout.
 *
 * @param {NavigationProps & WithWidth} param
 *
 * @returns {JSX.Element}
 */
const Header = ({
  routeId,
  title,
  width,
}: NavigationProps & WithWidth): JSX.Element => {
  const isDesktop = isWidthUp("lg", width)
  const [headerScrolled, setHeaderScrolled] = useState<boolean>(false)

  const [covidBannerOpen, setCovidBannerOpen] = useState<boolean>(false)
  const intl = useIntl()
  const currentLocale = intl.locale
  /**
   *
   * @function
   */
  const handleClick = () => {
    setCovidBannerOpen(false)
  }

  const bannerClassName = "covidBanner"

  /**
   * Event handler to update "headerScrolled" state
   *
   * @returns {void}
   */
  const updateHeaderScrolledByPosition = (): void => {
    if (!isDesktop) {
      return
    }

    const scrollHeight = window.pageYOffset

    if (scrollHeight < 1) {
      setHeaderScrolled(false)

      return
    }

    setHeaderScrolled(true)
  }

  /**
   * Effect to listen to on first component mount.
   *
   * @returns {void}
   */
  useEffect(() => {
    window.addEventListener("scroll", updateHeaderScrolledByPosition)

    return () => {
      window.removeEventListener("scroll", updateHeaderScrolledByPosition)
    }
  }, [])

  return (
    <Fixed
      as={"header"}
      sx={headerContainerStyles}
      className={headerScrolled ? "Scrolled" : ""}
    >
      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={headerInnerStyles}>
          <Link to="/">
            <Logo alt={title} />
          </Link>
        </Box>
        <Box>
          <Navigation routeId={routeId} title={title} />
        </Box>
      </Flex>
      <div
        className={
          covidBannerOpen ? bannerClassName + " active" : bannerClassName
        }
      >
        {currentLocale == "en" ? (
          <div>
            <div className="txt" style={{ textAlign: "center" }}>
              {translate(intl, `homepage.covid.text`)}
            </div>
            {/* <div className="txt" style={{ textAlign: "center" }}>{translate(intl, `homepage.covid.text2`)}</div> */}
          </div>
        ) : (
          <div>
            <div className="txt" style={{ textAlign: "center" }}>
              {translate(intl, `homepage.covid.text`)}
            </div>
            {/* <div className="txt" style={{ textAlign: "center" }}>{translate(intl, `homepage.covid.text2`)}</div> */}
          </div>
        )}

        <div className="icon" onClick={handleClick}>
          <CloseIcon />
        </div>
      </div>
    </Fixed>
  )
}

export default withWidth()(Header)
