import React from "react"
import DefaultNavigation, { DefaultNavigationProps } from "./default"
import CollapsibleNavigation, {
  CollapsibleNavigationProps,
} from "./collapsible"

export interface NavigationProps
  extends DefaultNavigationProps,
    CollapsibleNavigationProps {}

const Navigation = ({ routeId, title }: NavigationProps) => {
  return (
    <div>
      <DefaultNavigation routeId={routeId} />
      <CollapsibleNavigation routeId={routeId} title={title} />
    </div>
  )
}

export default Navigation
