import mainRoutes from "./main"
import footerRoutes from "./footer"
import { form, learnMore } from "./common"
import { RouteFnProps, Route } from "../interfaces/route"

/**
 * Imports all routes from other route methods.
 *
 * @function
 *
 * @param {RouteFnProps} param
 *
 * @returns {Route[]}
 */
export default ({ intl }: RouteFnProps): Route[] => [
  ...mainRoutes({ intl }),
  ...footerRoutes({ intl }),
  // "home" route not added from common.ts
  // only because mainRoutes already have "homepage"
  // as one of the route ids.
  form({ intl }),
  learnMore({ intl }),
]
