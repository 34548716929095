import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../../../helpers/theme"

export const footerContainerStyles = (
  hasCTA: boolean = false
): SxStyleProp => ({
  bg: "white",
  minHeight: hasCTA ? createResponsiveStyles("80px", "150px", "200px") : "0",
  py: createResponsiveStyles(2, 4, 5),
  px: createResponsiveStyles(3, 7),
})

export const footerInnerStyles: SxStyleProp = {
  py: createResponsiveStyles(2, 2, 4),
  alignItems: "center",
  justifyContent: "space-between",
  maxHeight: createResponsiveStyles("80px", "100px"),
}

export const footerLogoStyles: SxStyleProp = {
  variant: "layout.logo",
}

export const footerListStyles: SxStyleProp = {
  m: 0,
  p: 0,
  pl: 4,
  justifyContent: "space-between",
  alignItems: "center",
  listStyleType: "none",
  "> li": {
    mt: "10px",
    mb: "10px",
    textAlign: "right",
    mr: createResponsiveStyles(5, 6, 8),
    ":first-of-type": {
      mr: "0",
    },
  },
}

export const footerLinkStyles: SxStyleProp = {
  variant: "buttons.navigation",
  letterSpacing: "unset",
  ":hover, :active, .active": {
    border: "none",
    fontWeight: "unset",
  },
}
