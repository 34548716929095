import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { useThemeUI } from "theme-ui"
import SiteMeta from "../interfaces/site-meta"
import { useStaticQuery, graphql } from "gatsby"

/**
 * Component used for rendering "SEO" based data. Uses "react-helmet".
 *
 * @param {SiteMeta} param
 *
 * @returns {JSX.Element}
 */
function SEO({ description, lang, meta, title }: SiteMeta): JSX.Element {
  const intl = useIntl()
  const { theme } = useThemeUI()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  // Not used anywhere as per request
  const metaDescription = description || site.siteMetadata.description
  const htmlLang = intl.locale || lang || "en"
  const metaTitle = `${title} | ${site.siteMetadata.title}`
  const defaultMeta = [
    {
      name: `description`,
      content: metaTitle, // or metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaTitle, // or metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    // Commented out as per request.
    // {
    //   name: `twitter:creator`,
    //   content: site.siteMetadata.author,
    // },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaTitle, // or metaDescription,
    },
    {
      name: `theme-color`, // Chrome, Firefox, Opera
      content: theme.colors ? theme.colors.primary : "#ffffff",
    },
    {
      name: `msapplication-navbutton-color`, // Windows
      content: theme.colors ? theme.colors.primary : "#ffffff",
    },
    {
      name: `apple-mobile-web-app-status-bar-style`, // iOS
      content: theme.colors ? theme.colors.primary : "#ffffff",
    },
  ]
  const htmlMeta = meta ? defaultMeta.concat(meta) : defaultMeta

  // Render the component using "Helmet"
  return (
    <Helmet
      htmlAttributes={{ lang: htmlLang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={htmlMeta}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
