import { Theme, createMuiTheme } from "@material-ui/core/styles"

/**
 * Material UI's palette
 *
 * 1. https://material-ui.com/customization/palette/
 *
 * @constant
 */
export const palette = {
  primary: {
    main: "#1B6F65",
  },
  text: {
    primary: "#262626",
    secondary: "#909F9E",
  },
  green: {
    main: "#009688",
  },
  red: {
    main: "#F44336",
  },
}

/**
 * Theme styles based on Material UI
 *
 * 1. overview - https://material-ui.com/customization/theming/
 * 2. palettes - https://material-ui.com/customization/palette/
 * 3. typography - https://material-ui.com/customization/typography/
 * 4. overrides - https://material-ui.com/customization/components/#global-theme-override
 *
 * @constant
 */
const materialTheme: Theme = createMuiTheme({
  palette,
  typography: {
    fontSize: 14, // 1rem = 16px
    fontFamily: ["'Open Sans'", "system-ui", "sans-serif"].join(","),
    body1: {
      lineHeight: 1.38,
      fontFamily: ["'Open Sans'", "system-ui", "sans-serif"].join(","),
    },
    body2: {
      fontFamily: ["'Open Sans'", "system-ui", "sans-serif"].join(","),
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: palette.green.main,
        },
        "&:hover:not($disabled):before": {
          borderBottomColor: palette.green.main,
        },
      },
    },
    MuiInputBase: {
      root: {
        lineHeight: 1.5,
        letterSpacing: "unset",
        caretColor: palette.green.main,
        fontFamily: ["'Open Sans'", "system-ui", "sans-serif"].join(","),
      },
    },
    MuiFormLabel: {
      root: {
        color: palette.text.secondary,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#DFF0EE",
      },
    },
  },
})

export default materialTheme
