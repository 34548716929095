/** @jsx jsx */
import { useIntl } from "react-intl"
import { Link } from "gatsby-plugin-intl"
import { jsx, Flex, Box } from "theme-ui"
import routes from "../../../../../routes/main"
import LanguageSwitcher from "./language-switcher"
import { defaultNavigationContainerStyles } from "../styles"

export interface DefaultNavigationProps {
  routeId: string
}

const DefaultNavigation = ({ routeId }: DefaultNavigationProps) => {
  const intl = useIntl()
  const includesFAQ = ["homepage", "physicians"].includes(routeId)

  return (
    <nav sx={defaultNavigationContainerStyles}>
      <Flex as={"ul"}>
        {routes({ intl }).map((link, i) => {
          return includesFAQ && link.id === "faqs" ? (
            <Box as={"li"} sx={{ display: "inline-flex" }} key={i}>
              <a sx={{ variant: "buttons.navigation" }} href={link.path}>
                {link.title}
              </a>
            </Box>
          ) : link.id !== "faqs" ? (
            <Box as={"li"} sx={{ display: "inline-flex" }} key={i}>
              <Link
                className={link.id === routeId ? "active" : ""}
                sx={{ variant: "buttons.navigation" }}
                to={link.path}
              >
                {link.title}
              </Link>
            </Box>
          ) : null
        })}
      </Flex>
      <LanguageSwitcher routeId={routeId} />
    </nav>
  )
}

export default DefaultNavigation
