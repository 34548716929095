/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"
import { useIntl } from "react-intl"
import { useEffect, useState } from "react"
import allRoutes from "../../../../../routes/all"
import { changeLocale } from "gatsby-plugin-intl"
import { capitalize } from "../../../../../helpers/string"
import { environmentVariable } from "../../../../../helpers/others"

interface LanguageSwitcherProps {
  routeId: string
}

const LanguageSwitcher = ({ routeId }: LanguageSwitcherProps) => {
  const intl = useIntl()
  const currentLanguage = intl.locale
  const nextLanguage = currentLanguage === "en" ? "fr" : "en"
  const [currentRoutePath, setCurrentRoutePath] = useState<string>("/")

  const availableLanguages = environmentVariable(
    "availableLanguages"
  ) as string[]
  const languageSwitcherLink = environmentVariable("languageSwitcherLink") as
    | string
    | null

  useEffect(() => {
    const currentRoute = allRoutes({ intl }).find(route => route.id === routeId)

    if (currentRoute) {
      setCurrentRoutePath(currentRoute.path)
    }
  }, [routeId])

  return availableLanguages.length === 1 && languageSwitcherLink ? (
    <Flex sx={{ justifyContent: "center" }}>
      <a
        sx={{ variant: "buttons.languageSwitcher", pt: 1 }}
        href={`${languageSwitcherLink}${currentRoutePath}`}
      >
        {capitalize(nextLanguage)}
      </a>
    </Flex>
  ) : (
    <Box
      as={"button"}
      sx={{ variant: "buttons.languageSwitcher" }}
      onClick={() => changeLocale(nextLanguage)}
    >
      {capitalize(nextLanguage)}
    </Box>
  )
}

export default LanguageSwitcher
