/** @jsx jsx */
import { IntlShape } from "react-intl"
import { jsx, Styled, SxStyleProp, Box } from "theme-ui"
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat"
import { Children, Fragment, ReactNodeArray, ReactElement } from "react"

/**
 * Converts array index to string based indices.
 *
 * Eg. 0 - first, 1 - second
 *
 * Note: Mainly used for translations.
 *
 * @function
 *
 * @param {number} max
 *
 * @returns {string[]}
 */
export const stringBasedArrayIndices = (max: number = 0): string[] => {
  const createFrom = ["first", "second", "third", "fourth", "fifth"]

  return Array.from(createFrom).slice(0, max)
}

/**
 * Returns translated string using react-intl's
 * formatMessage() function.
 *
 * @function
 *
 * @param {IntlShape} intl
 * @param {string} id
 *
 * @returns {string}
 */
export const translate = (intl: IntlShape, id: string): string =>
  intl.formatMessage({ id })

/**
 * Type declaration for element classes
 *
 * @interface
 */
interface TranslatedElementClasses {
  h1?: SxStyleProp
  h2?: SxStyleProp
  h3?: SxStyleProp
  h4?: SxStyleProp
  p?: SxStyleProp
  ul?: SxStyleProp
  li?: SxStyleProp
}

/**
 * Maps through chunks of translated messages
 * and returns them as React Fragments. Mainly done
 * for the purpose of adding keys.
 *
 * @function
 *
 * @param {ReactNodeArray} chunks
 *
 * @returns {JSX.Element[]}
 */
const mapTranslatedChunks = (chunks: ReactNodeArray): JSX.Element[] => {
  return Children.map(chunks, (child, i) => (
    <Fragment key={i}>{child}</Fragment>
  ))
}

/**
 * Provides translation based on default HTML elements.
 *
 * @function
 *
 * @param {IntlShape} intl
 * @param {string} id
 * @param {TranslatedElementClasses|undefined} classes
 *
 * @returns {string|ReactNodeArray}
 */
export const translateHtml = (
  intl: IntlShape,
  id: string,
  classes?: TranslatedElementClasses
): string | ReactNodeArray => {
  let h1Key = 0
  let h2Key = 0
  let h3Key = 0
  let h4Key = 0
  let iKey = 0
  let bKey = 0
  let pKey = 0
  let ulKey = 0
  let olKey = 0

  return intl.formatMessage(
    { id },
    {
      h1: (...chunks) => (
        <Styled.h1 key={`h1-${++h1Key}`} sx={classes ? classes.h1 : {}}>
          {chunks}
        </Styled.h1>
      ),
      h2: (...chunks) => (
        <Styled.h2 key={`h2-${++h2Key}`} sx={classes ? classes.h2 : {}}>
          {chunks}
        </Styled.h2>
      ),
      h3: (...chunks) => (
        <Styled.h3 key={`h3-${++h3Key}`} sx={classes ? classes.h3 : {}}>
          {chunks}
        </Styled.h3>
      ),
      h4: (...chunks) => (
        <Styled.h4 key={`h4-${++h4Key}`} sx={classes ? classes.h4 : {}}>
          {chunks}
        </Styled.h4>
      ),
      p: (...chunks) => (
        <Styled.p key={`p-${++pKey}`} sx={classes ? classes.p : {}}>
          {chunks}
        </Styled.p>
      ),
      b: (str: string) => <b key={`b-${++bKey}`}>{str}</b>,
      i: (str: string) => <i key={`i-${++iKey}`}>{str}</i>,
      ul: (...chunks: ReactNodeArray[]) => (
        <Box as={"ul"} sx={classes ? classes.ul : {}} key={`ul-${++ulKey}`}>
          {mapTranslatedChunks(chunks)}
        </Box>
      ),
      ol: (...chunks: ReactNodeArray[]) => (
        <Box as={"ol"} sx={classes ? classes.ul : {}} key={`ol-${++olKey}`}>
          {mapTranslatedChunks(chunks)}
        </Box>
      ),
      li: (str: string) => <Box as={"li"}>{str}</Box>,
    }
  )
}

/**
 * Provides translation based on custom values.
 *
 * @function
 *
 * @param {IntlShape} intl
 * @param {string} id
 * @param {Record<string,PrimitiveType|ReactElement|FormatXMLElementFn>} values
 *
 * @returns {string|ReactNodeArray}
 */
export const translateCustom = (
  intl: IntlShape,
  id: string,
  values: Record<string, PrimitiveType | ReactElement | FormatXMLElementFn>
): string | ReactNodeArray => intl.formatMessage({ id }, values)
