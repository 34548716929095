/** @jsx jsx  */
import {
  footerLogoStyles,
  footerListStyles,
  footerLinkStyles,
  footerInnerStyles,
  footerContainerStyles,
} from "./styles"
import { Link } from "gatsby"
import { Logo } from "./images"
import { useIntl } from "react-intl"
import { jsx, Flex, Box } from "theme-ui"
import routes from "../../../../routes/footer"
import FixedCTA, { FixedCTAProps } from "../../../fixed-cta"

export interface FooterProps extends FixedCTAProps {
  hasCTA?: boolean
}

const Footer = ({ routeId, hasCTA, pageTitle }: FooterProps) => {
  const intl = useIntl()

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Box sx={footerContainerStyles(hasCTA)}>
        <Flex sx={footerInnerStyles}>
          <Box sx={footerLogoStyles}>
            <Logo alt={"Facet - Logo"} />
          </Box>
          <Flex as={"ul"} sx={footerListStyles}>
            {routes({ intl }).map((link, i) => (
              <Box as={"li"} key={i}>
                <Link sx={footerLinkStyles} to={link.path}>
                  {link.title}
                </Link>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Box>
      {hasCTA ? <FixedCTA routeId={routeId} pageTitle={pageTitle} /> : null}
    </Flex>
  )
}

export default Footer
