/** @jsx jsx */
import { useIntl } from "react-intl"
import { jsx, Flex } from "theme-ui"
import { Link } from "gatsby-plugin-intl"
import { form } from "../../routes/common"
import { useState, useEffect } from "react"
import { translate } from "../../helpers/grammar"
import { isInViewport } from "../../helpers/others"
import { fixedCTAContainerStyles, fixedCTAButtonStyles } from "./styles"
import withWidth, { isWidthUp, WithWidth } from "@material-ui/core/withWidth"

/**
 * Type definition for props required for "FixedCTA" component.
 *
 * @interface
 */
export interface FixedCTAProps {
  routeId: string
  pageTitle: string
}

/**
 * Component rendering the floating CTA button on the site.
 * The text of the CTA changes as per the pageTitle.
 *
 * Used on "home" & "physicians" page.
 *
 * 1. Button
 *
 * Note: The position is "fixed" on desktop (lg) screens,
 * but "relative" in smaller screen sizes.
 *
 * @param {FixedCTAProps & WithWidth} props
 *
 * @returns {JSX.Element}
 */
const FixedCTA = (props: FixedCTAProps & WithWidth): JSX.Element => {
  const intl = useIntl()
  const { pageTitle } = props
  const formUrl = form({ intl })
  const isDesktop = isWidthUp("lg", props.width)
  const isTabletOrUp = isWidthUp("sm", props.width)

  const [visibility, setVisibility] = useState(false)

  /**
   * Sets button visibility to "true" if the HeroText's form button
   * is not in viewport. Else "false".
   *
   * @function
   *
   * @returns {void}
   */
  const displayButton = (): void => {
    const heroFormButton = document.getElementById("HeroFormButton")

    if (heroFormButton && isInViewport(heroFormButton)) {
      setVisibility(false)
    } else if (heroFormButton) {
      setVisibility(true)
    }
  }

  /**
   * On component mount, add a window scroll event listener
   * and set the visibility of the Fixed CTA button.
   */
  useEffect(() => {
    window.addEventListener("scroll", displayButton)

    return () => {
      window.removeEventListener("scroll", displayButton)
    }
  }, [])

  return (
    <Flex sx={fixedCTAContainerStyles(visibility, isDesktop, isTabletOrUp)}>
      <Link
        to={formUrl.path}
        sx={fixedCTAButtonStyles(isTabletOrUp)}
        state={{ routeId: props.routeId }}
      >
        {pageTitle ? translate(intl, `${pageTitle}.fixedCTA`) : formUrl.title}
      </Link>
    </Flex>
  )
}

export default withWidth()(FixedCTA)
