/** @jsx jsx */
import {
  fixedStyles,
  imageStyles,
  absoluteStyles,
} from "../../constants/theme/base"
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress"
import FormControlLabel, {
  FormControlLabelProps,
} from "@material-ui/core/FormControlLabel"
import { jsx, Box, SxProps } from "theme-ui"
import materialTheme from "../../constants/theme/material"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete"
import ListItemText, { ListItemTextProps } from "@material-ui/core/ListItemText"

/**
 * Default component for "absolute" position-ed elements.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const Absolute = (props: any): JSX.Element => (
  <Box sx={absoluteStyles} {...props} />
)

/**
 * Default component for "fixed" position-ed elements.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const Fixed = (props: any): JSX.Element => (
  <Box sx={fixedStyles} {...props} />
)

/**
 * Default component for responsive image elements.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const ResponsiveImage = (props: any): JSX.Element => (
  <img sx={imageStyles} {...props} />
)

/**
 * Material UI's "TextField" component using custom material theme.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const CustomTextField = (
  props: TextFieldProps & SxProps
): JSX.Element => {
  return (
    <ThemeProvider theme={materialTheme}>
      <TextField {...props} />
    </ThemeProvider>
  )
}

/**
 * Material UI's "ListItem" component using custom material theme.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const CustomListItemText = (
  props: ListItemTextProps & SxProps
): JSX.Element => {
  return (
    <ThemeProvider theme={materialTheme}>
      <ListItemText {...props} />
    </ThemeProvider>
  )
}

/**
 * Material UI's "Checkbox" component using custom material theme.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const CustomCheckbox = (props: CheckboxProps & SxProps): JSX.Element => {
  return (
    <ThemeProvider theme={materialTheme}>
      <Checkbox {...props} />
    </ThemeProvider>
  )
}

/**
 * Material UI's "FormControlLabel" component using custom material theme.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const CustomFormControlLabel = (
  props: FormControlLabelProps & SxProps
): JSX.Element => {
  return (
    <ThemeProvider theme={materialTheme}>
      <FormControlLabel {...props} />
    </ThemeProvider>
  )
}

/**
 * Material UI's "Autocomplete" component using custom material theme.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const CustomAutocomplete = (
  props: AutocompleteProps & SxProps
): JSX.Element => {
  return (
    <ThemeProvider theme={materialTheme}>
      <Autocomplete {...props} />
    </ThemeProvider>
  )
}

/**
 * Material UI's "LinearProgress" component using custom material theme.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 */
export const CustomLinearProgress = (
  props: LinearProgressProps & SxProps
): JSX.Element => {
  return (
    <ThemeProvider theme={materialTheme}>
      <LinearProgress variant="determinate" {...props} />
    </ThemeProvider>
  )
}
