import {
  isIOS,
  isOpera,
  isAndroid,
  isWinPhone,
  isMobileOnly,
} from "react-device-detect"
import { FC, useEffect } from "react"

/**
 * Type declaration for an environment variable.
 *
 * @type
 */
type EnvironmentVariable = string | string[] | boolean | null

/**
 * Type definition for environment variables.
 *
 * @interface
 */
interface EnvironmentVariables {
  [key: string]: EnvironmentVariable
}

/**
 * Get an environment variable. Mainly used for converting
 * string based values into arrays & booleans when required.
 *
 * @function
 *
 * @param {string} name
 *
 * @returns {string|string[]|boolean|null}
 */
export const environmentVariable = (
  name: string
): string | string[] | boolean | null => {
  const defaultSteps = ["medications", "howTo", "download"]

  let patientSteps: EnvironmentVariable =
    process.env.GATSBY_PATIENT_STEPS || null
  patientSteps = patientSteps
    ? (patientSteps as string).split(",")
    : defaultSteps

  let physicianSteps: EnvironmentVariable =
    process.env.GATSBY_PHYSICIAN_STEPS || null
  physicianSteps = physicianSteps
    ? (physicianSteps as string).split(",")
    : defaultSteps

  let availableLanguages: EnvironmentVariable =
    process.env.GATSBY_AVAILABLE_LANGUAGES || null
  availableLanguages = availableLanguages
    ? (availableLanguages as string).split(",")
    : [`en`, `fr`]

  const environmentVariables: EnvironmentVariables = {
    appEnv: process.env.GATSBY_APP_ENV || null,
    appName: process.env.GATSBY_APP_NAME || null,
    appType: process.env.GATSBY_APP_TYPE || null,
    apiUrl: process.env.GATSBY_API_URL || "",
    policyNo: process.env.GATSBY_POLICY_NO || "",
    patientSteps,
    physicianSteps,
    enableUBCCheckbox:
      process.env.GATSBY_ENABLE_UBC_CHECKBOX === "true" ? true : false,
    availableLanguages,
    defaultLanguage: availableLanguages ? availableLanguages[0] : `en`,
    languageSwitcherLink: process.env.GATSBY_LANGUAGE_SWITCHER_LINK || null,
  }

  return environmentVariables[name] || null
}

/**
 * Used for checking if an HTMLElement is in viewport.
 *
 * @function
 *
 * @param {HTMLElement} elem
 *
 * @returns {boolean}
 */
export const isInViewport = (elem: HTMLElement): boolean => {
  const bounding = elem.getBoundingClientRect()

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Full height box (React based Functional Component).
 *
 * Fixes mobile phones issue with 100vh.
 *
 * @returns {null}
 */
export const MFullHeight: FC = (): null => {
  /**
   * Set a "vh" variable in the DOM styles.
   */
  const setHeight = () => {
    const vh = window.innerHeight * 0.01

    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  /**
   * On component mount, add a window resize event listener
   * and set the "vh" variable value based on window height.
   */
  useEffect(() => {
    setHeight()

    window.addEventListener("resize", setHeight)

    return () => {
      window.removeEventListener("resize", setHeight)
    }
  }, [])

  return null
}

/**
 * Uses "react-device-detect" to validate if it is an actual mobile device.
 *
 * Note: Is defined only on page load.
 *
 * @constant
 *
 * @returns {boolean}
 */
export const isActualMobileDevice: boolean =
  (isAndroid || isIOS || isWinPhone || isOpera) && isMobileOnly
