import { SxStyleProp } from "theme-ui"

export const fixedCTAContainerStyles = (
  visibility: boolean = false,
  isDesktop: boolean = false,
  isTabletOrUp: boolean = false
): SxStyleProp => {
  if (isTabletOrUp) {
    return {
      zIndex: "fixedCTA",
      right: isDesktop ? 12 : 8,
      bottom: isDesktop ? 6 : 7,
      position: isDesktop ? "fixed" : "relative",
      textAlign: "right",
      opacity:
        isDesktop && visibility // Switch visibility
          ? 1
          : isDesktop && !visibility // Because visibility is always 1 in tablet, we need this condition
          ? 0
          : 1,
      transition: "all 0.5s ease",
      justifyContent: "flex-end",
    }
  }

  // For mobile (less than "sm")
  return {
    position: "flex",
    marginRight: "0",
    textAlign: "center",
    justifyContent: "flex-end",
  }
}

export const fixedCTAButtonStyles = (
  isTabletOrUp: boolean = false
): SxStyleProp => {
  return isTabletOrUp
    ? {
        px: 7,
        fontWeight: "dark",
        width: "231px",
        variant: "buttons.roundedPrimary",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      }
    : {
        px: 7,
        fontWeight: "dark",
        width: "100%",
        variant: "buttons.primary",
        borderWidth: 0,
        borderRadius: 0,
      }
}
