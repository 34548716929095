import { SxStyleProp } from "theme-ui"

export * from "./navigation"

/**
 * Header - Container (Fixed)
 */
export const headerContainerStyles: SxStyleProp = {
  variant: "layout.header",
}

/**
 * Header - Inner (Box)
 */
export const headerInnerStyles: SxStyleProp = {
  variant: "layout.logo",
}
