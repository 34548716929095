import { translate } from "../helpers/grammar"
import { Route, RouteFnProps } from "../interfaces/route"

/**
 * Returns the routes for the footer section of the "MainLayout".
 *
 * @function
 *
 * @param {RouteFnProps} param
 *
 * @returns {Route}
 */
export default ({ intl }: RouteFnProps): Route[] => [
  {
    id: "privacy",
    path: "/privacy",
    title: translate(intl, "footer.menu.privacy"),
  },
]
