/**
 * Capitalize first character of a string.
 *
 * @function
 *
 * @param {string} value
 *
 * @returns {string}
 */
export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * Trims string (for unsupported browsers).
 *
 * @function
 *
 * @param {string} input
 *
 * @returns {string}
 */
export const trim = (input: string): string => {
  return input.replace(/^\s+|\s+$/gm, "")
}

/**
 * Validates if the input string is an email.
 *
 * @function
 *
 * @param {string} email
 *
 * @returns {boolean}
 */
export const validateEmail = (email: string): boolean => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

/**
 * Validates the input string length.
 *
 * @function
 *
 * @param {string} value
 * @param {number} minLength
 * @param {number} maxLength
 *
 * @returns {boolean}
 */
export const validateLength = (
  value: string,
  minLength: number = 2,
  maxLength: number = 255
): boolean => {
  return value.length >= minLength && value.length <= maxLength
}
