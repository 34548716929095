/** @jsx jsx */
import {
  collapsibleNavigationListStyles,
  collapsibleNavigationSidebarStyles,
  collapsibleNavigationHamburgerStyles,
  collabsibleNavigationContainerStyles,
  collabsibleNavigationCloseButtonStyles,
  collapsibleNavigationHamburgerIconStyles,
  collabsibleNavigationSidebarContainerStyles,
} from "../styles"
import { useState } from "react"
import { useIntl } from "react-intl"
import { Link } from "gatsby-plugin-intl"
import { jsx, Flex, Box } from "theme-ui"
import routes from "../../../../../routes/main"
import { DefaultNavigationProps } from "./default"
import LanguageSwitcher from "./language-switcher"
import { MobileLogo, HamburgerIcon } from "../images"
import SiteMeta from "../../../../../interfaces/site-meta"
import { translate } from "../../../../../helpers/grammar"

export interface CollapsibleNavigationProps
  extends DefaultNavigationProps,
    Pick<SiteMeta, "title"> {}

const CollapsibleNavigation = ({
  routeId,
  title,
}: CollapsibleNavigationProps) => {
  const intl = useIntl()
  const includesFAQ = ["homepage", "physicians"].includes(routeId)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggleMenuState = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Flex sx={collabsibleNavigationContainerStyles}>
      <Box
        as={"button"}
        onClick={toggleMenuState}
        sx={collapsibleNavigationHamburgerStyles}
      >
        <HamburgerIcon
          alt={"Menu Icon"}
          sx={collapsibleNavigationHamburgerIconStyles}
        />
      </Box>
      <Box
        className={isOpen ? "active" : ""}
        sx={collapsibleNavigationSidebarStyles}
      >
        <Box
          sx={{
            p: [3, 4, 5],
            height: "100%",
            position: "relative",
          }}
        >
          <Box
            as={"button"}
            sx={collabsibleNavigationCloseButtonStyles}
            onClick={toggleMenuState}
          >
            {translate(intl, "header.closeButton")}
          </Box>
          <Flex as={"nav"} sx={collabsibleNavigationSidebarContainerStyles}>
            <Flex as={"ul"}>
              <Box as={"li"} sx={collapsibleNavigationListStyles}>
                <Box sx={{ variant: "layout.mobileLogo", m: "0 auto" }}>
                  <Link to="/">
                    <MobileLogo alt={`${title} - Nav Logo`} />
                  </Link>
                </Box>
              </Box>
              <Box as={"li"} sx={collapsibleNavigationListStyles}>
                <LanguageSwitcher routeId={routeId} />
              </Box>
              {routes({ intl }).map((link, i) => {
                return includesFAQ && link.id === "faqs" ? (
                  <Box as={"li"} sx={collapsibleNavigationListStyles} key={i}>
                    <a
                      sx={{ variant: "buttons.navigation" }}
                      onClick={toggleMenuState}
                      href={link.path}
                    >
                      {link.title}
                    </a>
                  </Box>
                ) : link.id !== "faqs" ? (
                  <Box as={"li"} sx={collapsibleNavigationListStyles} key={i}>
                    <Link
                      className={link.id === routeId ? "active" : ""}
                      sx={{ variant: "buttons.navigation" }}
                      to={link.path}
                    >
                      {link.title}
                    </Link>
                  </Box>
                ) : null
              })}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default CollapsibleNavigation
