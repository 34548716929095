import { translate } from "../helpers/grammar"
import { Route, RouteFnProps } from "../interfaces/route"

/**
 * Returns the route for the home page
 *
 * @function
 *
 * @param {RouteFnProps} param
 *
 * @returns {Route}
 */
export const home = ({ intl }: RouteFnProps): Route => ({
  id: "homepage",
  path: "/",
  title: translate(intl, "common.links.home"),
})

/**
 * Returns the route for the forms page
 *
 * @function
 *
 * @param {RouteFnProps} param
 *
 * @returns {Route}
 */
export const form = ({ intl }: RouteFnProps): Route => ({
  id: "form",
  path: "/forms",
  title: translate(intl, "common.links.form"),
})

/**
 * Returns the route for the FAQ section on
 * the home & physicians page.
 *
 * @function
 *
 * @param {RouteFnProps} param
 *
 * @returns {Route}
 */
export const learnMore = ({ intl }: RouteFnProps): Route => ({
  id: "learnMore",
  path: "#learnMore",
  title: translate(intl, "common.links.learn"),
})
